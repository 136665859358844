<template>
  <div>
    <div class="float-sm-left">
      <page-title />
    </div>
    <div class="float-sm-right mt-1 mb-2">
      <b-button v-b-modal.create>
        <b-icon icon="plus" /> Create
      </b-button>
    </div>
    <table-sort-mobile
      :sort-by.sync="sortBy"
      :sort-options="sortOptions"
      :sort-desc.sync="sortDesc"
    >
      <b-form-input
        v-model="filter.login"
        class="mb-2"
        placeholder="Filter by login"
        debounce="300"
        autofocus
      />
      <b-form-checkbox
        v-model="filter.isActive"
        class="float-left mr-2"
        name="check-button"
        switch
      >
        Active <span class="text-muted">({{ filter.isActive === null ? 'Any' : (filter.isActive ? 'Yes' : 'No') }})</span>
      </b-form-checkbox>
      <b-icon
        v-if="filter.isActive !== null"
        icon="dash-circle"
        title="Reset filter"
        @click="filter.isActive = null"
      />
    </table-sort-mobile>
    <b-table
      ref="table"
      :fields="fields"
      :items="itemsProvider"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      api-url="/user/list"
      stacked="sm"
      show-empty
      no-local-sorting
    >
      <template #head(login)="data">
        {{ data.label }}
        <b-input
          v-model="filter.login"
          size="sm"
          debounce="300"
          autofocus
        />
      </template>

      <template #head(isActive)="data">
        <div>{{ data.label }}</div>
        <b-form-checkbox
          v-model="filter.isActive"
          class="float-left mr-2"
          name="check-button"
          switch
        >
          <span class="text-muted">{{ filter.isActive === null ? 'Any' : (filter.isActive ? 'Yes' : 'No') }}</span>
        </b-form-checkbox>
        <b-icon
          v-if="filter.isActive !== null"
          icon="dash-circle"
          title="Reset filter"
          @click="filter.isActive = null"
        />
      </template>

      <template #cell(actions)="{ item }">
        <b-form inline>
          <b-button
            class="mr-2"
            size="sm"
            title="Reset password"
            @click="showResetPasswordConfirm(item)"
          >
            <b-icon icon="arrow-repeat" />
          </b-button>
          <b-form-checkbox
            v-model="item.autocompleteEnabled"
            title="Switch autocomplete enabled/disabled"
            switch
            @change="switchAutocomplete(item)"
          >
            Autocomplete
          </b-form-checkbox>
        </b-form>
      </template>
    </b-table>
    <b-pagination
      v-if="totalRows > perPage"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    />

    <b-modal
      id="create"
      title="Create user"
      :ok-disabled="!canSubmitCreate"
      @ok="submitCreate"
    >
      <b-alert
        :show="!!createError"
        variant="danger"
        v-text="createError"
      />
      <b-alert
        :show="!!createSuccess"
        variant="success"
        v-text="createSuccess"
      />
      <b-form @submit.prevent="create()">
        <b-form-group
          :invalid-feedback="uniqueError"
          :state="uniqueValidationState"
        >
          <b-input-group>
            <b-form-input
              v-model="login"
              v-debounce="checkLoginUnique"
              required
              placeholder="Login"
              :state="uniqueValidationState"
              autofocus
            />
          </b-input-group>
        </b-form-group>
      </b-form>
      <template #modal-ok>
        <b-spinner
          v-if="creating"
          small
        /> Create
      </template>
    </b-modal>

    <b-modal
      id="reset-password"
      title="Confirm password reset"
      :ok-disabled="!!resetPasswordSuccess"
      @ok="resetPassword"
    >
      <b-alert
        :show="!!resetPasswordSuccess"
        variant="success"
        v-text="resetPasswordSuccess"
      />
      <b-alert
        :show="!!resetPasswordError"
        variant="danger"
        v-text="resetPasswordError"
      />
      <template v-if="!resetPasswordSuccess">
        Are you sure to reset password for user <b>{{ resetPasswordUser.login }}</b>?
      </template>
      <template #modal-ok>
        <b-spinner
          v-if="resetting"
          small
        /> Yes, reset
      </template>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/ApiService'
import PageTitle from '@/components/PageTitle'
import { getRequestErrorText } from '@/utils'
import table from '@/mixins/table'
import TableSortMobile from '@/components/TableSortMobile'

export default {
  name: 'Users',
  components: { TableSortMobile, PageTitle },
  mixins: [table],
  data () {
    return {
      login: '',
      uniqueError: '',
      creating: false,
      createSuccess: '',
      createError: '',
      fields: [
        {
          key: 'id',
          sortable: true
        },
        {
          key: 'login',
          sortable: true
        },
        {
          key: 'role',
          sortable: true
        },
        {
          key: 'addedAt',
          sortable: true
        },
        {
          key: 'isActive',
          sortable: true
        },
        {
          key: 'firstLoggedAt',
          sortable: true
        },
        {
          key: 'actions'
        }
      ],
      filter: {
        login: '',
        isActive: null
      },
      resetPasswordUser: {},
      resetting: false,
      resetPasswordError: '',
      resetPasswordSuccess: ''
    }
  },
  computed: {
    uniqueValidationState () {
      return this.uniqueError ? false : null
    },
    canSubmitCreate () {
      return this.login && !this.uniqueError
    }
  },
  watch: {
    login () {
      this.createError = ''
      this.uniqueError = ''
    }
  },
  methods: {
    submitCreate (event) {
      event.preventDefault()

      this.create()
    },
    create () {
      this.creating = true
      this.createSuccess = ''
      this.createError = ''

      ApiService.post('/user', { login: this.login })
        .then(response => {
          this.createSuccess = response.data
          this.refresh()
        })
        .catch(error => {
          this.createError = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.creating = false
        })
    },
    checkLoginUnique (login) {
      if (login) {
        this.uniqueError = ''

        ApiService.get(`/user/login/${login}/is-unique`)
          .catch(error => {
            this.uniqueError = getRequestErrorText(error, '')
          })
      }
    },
    resetPassword (event) {
      event.preventDefault()

      this.resetting = true
      this.resetPasswordError = ''
      this.resetPasswordSuccess = ''

      ApiService.post(`/user/password/reset?id=${this.resetPasswordUser.id}`)
        .then(response => {
          this.resetPasswordSuccess = response.data
        })
        .catch(error => {
          this.resetPasswordError = getRequestErrorText(error)
        })
        .finally(() => {
          this.resetting = false
        })
    },
    showResetPasswordConfirm (user) {
      this.resetPasswordUser = user
      this.resetPasswordError = ''
      this.resetPasswordSuccess = ''
      this.$bvModal.show('reset-password')
    },
    switchAutocomplete (user) {
      ApiService.post(`/user/acp?id=${user.id}`, { state: user.autocompleteEnabled ? 0 : 1 })
        .catch(error => {
          user.autocompleteEnabled = !user.autocompleteEnabled

          this.$bvToast.toast(getRequestErrorText(error), {
            title: 'Switch autocomplete error',
            variant: 'danger'
          })
        })
    }
  }
}
</script>
